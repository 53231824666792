.galeria-container {
  width: 100%;
  padding: 80px 0;
  /* Ajustei o padding para telas menores */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #F4F7FA;
}

.swiper-pagination{
  position: relative !important;
  margin-top: 20px;
}

.swiper-pagination-bullet {
  background-color: var(--cor-principal) !important;
}


.galeria-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  width: 70%;
}

.galeria-container h2 {
  font-size: 38px;
  line-height: 1.2em;
  text-align: center;
  max-width: 800px;
  color: var(--cor-secundaria);
  margin-bottom: 40px;
}

.galeria-foto-item {
  width: 100%;
  height: 250px;
  box-sizing: border-box;
}

.galeria-foto-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Swiper CSS */
.swiper-container {
  width: 100%;
  height: auto;
  /* Ajuste para garantir que o swiper ocupe a altura necessária */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 766px) {
  .galeria-container {
    width: 100%;
    padding: 40px 20px 60px 20px;
  }

  .galeria-container h2 {
    font-size: 28px;
    width: 100%;
  }

  .galeria-foto-item {
    width: 100%;
    height: 350px;
    box-sizing: border-box;
  }

  .galeria-foto-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

}

@media (min-width: 767px) and (max-width: 1024px) {
  .galeria-container {
    width: 100%;
    padding: 80px 100px;
  }

  .galeria-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
  }


}

@media (min-width: 1025px) and (max-width: 1367px) {
  .galeria-container {
    width: 100%;
    padding: 80px 80px;
  }

  .galeria-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 90%;
  }
}