

.rich-text-content ul li{
    padding: 6px 0;
    margin-left: 20px;
    color: var(--cor-secundaria);
}
  
  .post-categoria {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
  }
  
  .post-page-data {
    font-size: 14px;
    color: #555;
  }
  
  .post-page-img {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .rich-text-content {
    margin-top: 20px;
    width: 100%;
    color: #000;
  }
  
  .rich-text-content img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .rich-text-content * {
    max-width: 100%;
    box-sizing: border-box;
  }


.post-details{
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content-post-page{
    width: 70%;
    margin-bottom: 40px;
}

.content-post-page h1{
    font-size: 32px;
    text-align: start;
    color: #000;
}

.content-post-page h2{
    font-size: 26px;
    color: #000;
}

.rich-text-content img {
    margin: 20px 0;
}

.post-details .content-post-page .post-categoria{
    text-decoration: none;
}
.post-page-categoria{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #000;
}

.content-post-page h3{
    font-size: 22px;
    color: #000;
    line-height: 1em;
    margin: 20px 0;
}

.content-post-page p{
    margin: 10px 0;
}

.post-page-data{
    font-size: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.post-page-img{
    margin: 30px 0;
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.subtitulo-blog{
    font-size: 26px;
    color: #000;
    padding: 10px 0;
}

.descricao-post{
    color: #000;
    font-size: 16px;
    line-height: 1.3em;
    padding: 10px 0;
}

@media (max-width: 766px) {
    .post-details{
        margin-top: 140px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .content-post-page{
        width: 90%;
        margin-bottom: 20px;
    }

    .content-post-page h3{
        font-size: 20px;
        color: #000;
        line-height: 1.2em;
        margin-bottom: 10px;
    }
    
    .post-page-categoria{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #000;
    }

    .post-page-data{
        font-size: 14px;
        font-weight: 500;
        color: #000;
    }
    
    .post-page-img{
        margin: 10px 0 0 0;
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    
    .subtitulo-blog{
        font-size: 26px;
        color: #000;
        padding: 10px 0;
    }
    
    .descricao-post{
        color: #000;
        font-size: 16px;
        line-height: 1.3em;
        padding: 10px 0;
    }
}

@media (min-width: 767px) and (max-width: 1024px){

    .content-post-page{
        width: 80%;
    }
    
    .post-page-categoria{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #000;
    }
    
    .content-post-page h3{
        font-size: 32px;
        color: #000;
    }
}
