/*SEÇÃO SOBRE */

.sobre-nos {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.cta-contrate a {
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.info-sobre-nos {
    width: 60%;
}

.sobre-nos h2 {
    color: var(--cor-principal);
    padding: 20px 0;
}

video {
    width: 100%;
    margin-bottom: 30px;
}

.cta-contrate .botoes-acao {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.cta-contrate .botoes-acao button {
    width: 100%;

}


/*FIM SEÇÃO SOBRE */

/*SEÇÃO SERVIÇOS */

.servicos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--background-claro);
}

.content-serviços {
    width: 70%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.lista-servicos {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 10px;
}

.card-descricao-servico img {
    padding: 15px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
}

.card-descricao-servico .icon1 {
    background-color: #006494;
}

.card-descricao-servico .icon2 {
    background-color: #6A51FF;
}

.card-descricao-servico .icon3 {
    background-color: #D81E5B;
}

.card-descricao-servico .icon4 {
    background-color: #136F63;
}

.card-descricao-servico .icon5 {
    background-color: #22223ba8;
}

.card-descricao-servico {
    display: flex;
    align-items: center;
}

.servicos h5 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--cor-principal);
    margin-bottom: 20px;
    letter-spacing: 0.2em;
    font-weight: 700;
}

.info-servicos {
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.info-servicos p {
    font-size: 14px;
}

.servicos h1 {
    max-width: 800px;
}

.card-descricao-servico p {
    line-height: 1.2em;
    margin-top: 8px;
}

/*FIM SEÇÃO SERVIÇOS */


.cta-contrate {
    width: 100%;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-cta {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cta-contrate p {
    width: 90%;
    font-size: 18px;
    line-height: 1.2em;
    margin-top: 20px;
}

.infos-cta {
    width: 100%;
}

.content-diferenciais {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.diferenciais .box-cardsInfo-descricao h3 {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}
.diferenciais .cardsInfo .box-cardsInfo-descricao {
    border: 1px solid #fff;
  
}

.diferenciais .box-cardsInfo-descricao p {
    line-height: 1.2em;
    color: #fff;
    font-size: 14px;
}

.diferenciais .group-cardsInfo{
    width: 70%;
}

.diferenciais .cardsInfo .box-cardsInfo-descricao {
    padding: 20px;
    border: 1px solid #fff;
    height: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.diferenciais .cardsInfo {
    padding: 0px;
}


.diferenciais {
    height: 100%;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--cor-principal);
    color: #fff;
}

.cards-diferenciais {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}

.icon-diferenciais img {
    width: 100%;
    height: 100%;
}

.diferenciais h5 {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 20px;
    letter-spacing: 0.2em;
    font-weight: 600;
}

.card-descricao-diferenciais {
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-servicos {
    text-align: left;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.diferenciais h1 {
    line-height: 1.2em;
    max-width: 800px;
    color: #fff;
    margin-bottom: 30px;
}

.card-descricao-diferenciais h3 {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
}

.card-descricao-diferenciais p {
    line-height: 1.2em;
    color: #fff;
    font-size: 14px;
}


.depoimentos {
    height: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--background-claro);
    color: var(--cor-secundaria);
}

.perfil {
    width: 100%;
    display: flex;
    align-items: center;
}

.cards-depoimentos {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}

.card-descricao-depoimentos .perfil img {
    padding: 15px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.card-descricao-depoimentos {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px 20px 30px 20px;
    display: flex;
    flex-direction: column;
}

.depoimentos h2 {
    font-size: 38px;
    line-height: 1.2em;
    text-align: center;
    max-width: 800px;
    color: var(--cor-secundaria);
    margin-bottom: 40px;
}

.card-descricao-depoimentos h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}

.card-descricao-depoimentos p {
    width: 100%;
}

@media (max-width: 566px) {

    .cta-contrate .botoes-acao {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

@media (max-width: 766px) {

    .diferenciais .group-cardsInfo{
        width: 100%;
    }

    
    .sobre-nos {
        padding: 40px 20px;
    }

    .info-sobre-nos {
        width: 100%;
    }

    .content-serviços {
        width: 90%;
    }

    .servicos h1 {
        line-height: 1em;
    }

    .lista-servicos {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .card-descricao-servico h2 {
        font-size: 20px;
    }

    .servicos h5 {
        display: none;
    }


    .cta-contrate {
        width: 100%;
        padding: 40px 0;
    }

    .content-cta {
        width: 80%;
        display: block;
        justify-content: center;
        align-items: center;
    }


    .cta-contrate p {
        width: 100%;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .infos-cta {
        width: 100%;
        text-align: center;
    }


    .cards-diferenciais {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .content-diferenciais {
        width: 90%;
    }


    .card-descricao-diferenciais img {
        padding: 10px;
    }

    .diferenciais h5 {
        display: none;
    }



    .diferenciais h2 {
        font-size: 28px;
    }

    .card-descricao-diferenciais h3 {
        margin-bottom: 10px;
        font-size: 26px;
        font-weight: bold;
    }

    .card-descricao-diferenciais p {
        font-weight: 500;
        width: 80%;
        line-height: 1em;
    }



    .cards-depoimentos {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        width: 90%;
    }

    .depoimentos h2 {
        font-size: 28px;
        line-height: 1.2em;
        text-align: center;
        max-width: 800px;
        color: var(--cor-secundaria);
        margin-bottom: 40px;
    }

}

@media (min-width: 767px) and (max-width: 1024px) {

    .diferenciais .group-cardsInfo{
        width: 100%;
    }

    .cta-contrate .botoes-acao {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }


    .sobre-nos {
        padding: 40px 0;
    }

    .info-sobre-nos {
        width: 80%;
    }

    .content-serviços {
        width: 80%;
    }

    .servicos h1 {
        line-height: 1em;
    }

    .card-descricao-servico h2 {
        font-size: 20px;
    }

    .card-descricao-servico h3 {
        margin-bottom: 4px;
        font-size: 20px;
    }

    .cta-contrate {
        width: 100%;
        padding: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .infos-cta {
        width: 100%;
        margin-bottom: 20px;
    }

    .content-cta {
        width: 80%;
        display: block;
        justify-content: center;
        align-items: center;
    }

    .cards-diferenciais {
        gap: 15px;
    }

    .content-diferenciais {
        width: 80%;
        padding: 40px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }


    .card-descricao-diferenciais img {
        padding: 10px;
    }

    .diferenciais h5 {
        display: none;
    }

    .diferenciais h1 {
        width: 100%;
    }

    .card-descricao-diferenciais {
        padding: 20px 0px;
    }

    .card-descricao-diferenciais h3 {
        padding: 10px 0;
        font-size: 22px;
        font-weight: bold;
        line-height: 1em;
    }

    .card-descricao-diferenciais p {
        width: 80%;
    }

    .depoimentos {
        padding: 40px 0;
    }

    .depoimentos h1 {
        width: 80%;
    }

    .cards-depoimentos {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
        width: 80%;
        margin-top: 10px;
    }

    .depoimentos h2 {
        font-size: 28px;
        line-height: 1.2em;
        text-align: center;
        max-width: 800px;
        color: var(--cor-secundaria);
        margin-bottom: 40px;
    }
}

@media (min-width: 1025px) and (max-width: 1367px) {
    .cta-contrate {
        width: 100%;
        padding: 40px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .infos-cta {
        width: 100%;
        margin-bottom: 20px;
    }

    .cards-diferenciais {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    .card-descricao-diferenciais {
        padding: 20px;

    }

    .card-descricao-diferenciais img {
        padding: 10px;
    }

    .cards-depoimentos {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        margin-top: 10px;
    }


}