@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --cor-principal: #FF6600;
  --cor-secundaria: #161C2D;
  --verde-whatsapp: #2BCC63;
  --background-claro: #DFECFA;
  --backdground-padrão: #fff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: var(--cor-secundaria);

}

h1 {
  font-size: 38px;
  text-align: center;
  line-height: 1.2em;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
  color: #333333;
}

h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1em;
}

.botoes-acao {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.botoes-acao button, button {
  padding: 15px 20px;
  width: 30%;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s
}

button:hover {
  background-color: #161C2D;
  transform: translateY(-2px);
}

/* customToast.css */
.Toastify__close-button {
  width: 60px;
  font-size: 30px; 
  background: transparent; 
  border: none;
  cursor: pointer; 
}

.Toastify__close-button:hover {
  background-color: transparent;
  transform: translateY(0);
}

.submit-button:active {
  background-color: var(--cor-principal);
  transform: translateY(0);
}

.botoes-acao .botao-orcamento, .botao-orcamento {
  background-color: var(--cor-principal);
}

.botoes-acao .botao-whats {
  background-color: var(--verde-whatsapp);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 766px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
  }

  .botoes-acao {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .botoes-acao button {
    padding: 10px 10px;
    font-size: 16px;
    line-height: 1em;
  }

  .botoes-acao button, button {
    width: 80%;
    font-size: 16px;
    
  }
  
}

@media (min-width: 767px) and (max-width: 1024px) {
  .botoes-acao {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1367px) {}