.faq-accordion {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.content-faq {
    width: 70%;
    padding: 40px 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    padding: 20px;
    border-radius: 8px;
    background-color: #F4F7FA;
    font-size: 20px;
}

.faq-accordion h2 {
    font-size: 38px;
    margin-bottom: 60px;
    text-align: center;
}

.faq-accordion h3 {
    font-size: 20px;
}

.faq-question.active {
    background-color: var(--background-claro);
}

.toggle-icon {
    font-size: 30px;
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    padding: 0 20px;
    border: 0;
    font-size: 20px;
    font-weight: 500;
}

.faq-answer.show {
    margin-bottom: 20px;
}

@media (max-width: 766px) {
    .faq-accordion {
        width: 100%;

    }

    .content-faq {
        width: 90%;
    }

    .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        padding: 20px;
        border-radius: 8px;
        background-color: #F4F7FA;
        font-size: 16px;
    }

    .faq-accordion h2 {
        font-size: 28px;
        margin-bottom: 40px;
        text-align: center;
    }

    .faq-question.active {
        background-color: var(--background-claro);
    }

    .toggle-icon {
        font-size: 20px;
    }

    .faq-answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out, padding 0.5s ease-out;
        padding: 0 20px;
        margin: 0;
    }

    .faq-answer.show {
        display: block;

    }

}

@media (min-width: 767px) and (max-width: 1024px) {
    .faq-accordion {
        width: 100%;
    }

    .content-faq {
        width: 80%;
        padding: 40px 0;
    }

    .faq-item {
        margin-bottom: 10px;
    }

    .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        padding: 20px;
        border-radius: 8px;
        background-color: #F4F7FA;
        font-size: 16px;
    }

    .faq-accordion h2 {
        font-size: 28px;
        margin-bottom: 40px;
        text-align: center;
    }

    .faq-question.active {
        background-color: var(--background-claro);
    }

    .toggle-icon {
        font-size: 20px;
    }

    .faq-answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out, padding 0.5s ease-out;
        padding: 0 20px;
        margin: 0;
    }

    .faq-answer.show {
        display: block;

    }

}

@media (min-width: 1025px) and (max-width: 1367px) {
    .faq-accordion {
        width: 100%;
        padding: 0px;
    }

    .content-faq {
        width: 80%;
        padding: 40px 0;
    }

    .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        padding: 20px;
        border-radius: 8px;
        background-color: #F4F7FA;
        font-size: 16px;
    }

    .faq-accordion h2 {
        font-size: 28px;
        margin-bottom: 40px;
        text-align: center;
    }

    .faq-question.active {
        background-color: var(--background-claro);
    }

    .toggle-icon {
        font-size: 20px;
    }

    .faq-answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out, padding 0.5s ease-out;
        padding: 0 20px;
        margin: 0;
    }

    .faq-answer.show {
        display: block;

    }
}