#guarda-moveis p {
    margin-bottom: 20px;
}

#guarda-moveis span {
    display: block;
}

#guarda-moveis h4 {
    font-weight: 300;
    font-style: italic;
    font-size: 28px;
    margin-bottom: 20px;
}

#guarda-moveis .botoes-acao {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 00px;
    margin-bottom: 20px;
}
#guarda-moveis .botoes-acao button {
    width: 30%;
}


#guarda-moveis .content-textos {
    margin-bottom: 20px;
}

@media (max-width: 766px) {
    #guarda-moveis h4 {
        font-size: 22px;
    }

    #guarda-moveis .botoes-acao {
        padding: 0px;
    }

    #guarda-moveis .botoes-acao button {
        width: 100%;
    }
}

@media (min-width: 767px) and (max-width: 1024px) {
    #guarda-moveis h4 {
        font-size: 22px;
    }

    #guarda-moveis .botoes-acao {
        padding: 0px;
    }
}
