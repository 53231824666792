

.group-relatedPosts{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.post-list-relatedPosts {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 40px;
}

.title-main-relatedPosts{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 80px;
}

.title-main-relatedPosts h2 {
    width: 70%;
    color: #000;
    font-size: 32px;
    font-weight: bold;
}

.post-img-relatedPosts img {
   width: 100%;
   height: 180px;
   object-fit: cover;
   object-position: center;
}

.post-categoria-relatedPosts{
    font-weight: 500;
    font-size: 12px;
    color: #000;
    cursor: pointer;
    
    padding: 6px 0;
}

.post-categoria-relatedPosts:hover {
    color: var(--cor-principal);
}

.post-content-relatedPosts a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    line-height: 1.2em;
}

.post-content-relatedPosts  a:hover {
    color: var(--cor-principal);
}

.post-item-relatedPosts .post-intro-relatedPosts{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2em;
    width: 100%;
    color: var(--cor-secundaria);
    margin: 6px 0 ;
}

.post-item-relatedPosts .post-data-relatedPosts{
    font-size: 12px;
    font-weight: 500;
}

@media (max-width: 767px) {

    .post-list-relatedPosts {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 40px;
    }
    
    .title-main-relatedPosts{
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
    
    .title-main-relatedPosts h2 {
        width: 90%;
        color: #000;
        font-size: 32px;
        font-weight: bold;
    }
    
}

@media (min-width: 768px) and (max-width: 1024px){
    .post-list-relatedPosts {
        width: 80%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 40px;
    }
    
    .title-main-relatedPosts{
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
    
    .title-main-relatedPosts h2 {
        width: 80%;
        color: #000;
        font-size: 32px;
        font-weight: bold;
    }
    
}

@media (min-width: 1025px) and (max-width: 1367px){
    .post-list-relatedPosts {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        margin-bottom: 40px;
    }
    

}