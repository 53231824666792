

.ql-tooltip.ql-editing {
    opacity: 1;
}

.rich-text-content h2 {
    margin: 20px 0;
    color: #000;
}

.rich-text-content h1 {
    margin: 20px 0;
    color: #000;

}

.ql-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 1000;
  }

.ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    height: 400px;
}

.ql-toolbar {
    border-bottom: none;
    border-radius: 6px 6px 0 0;
}

.ql-editor {
    border-top: none;
    border-radius: 0 0 6px 6px;
    padding: 10px;
}

.ql-editor h1 {
    color: #000;
    text-align: left;
    margin: 20px 0;
}

.ql-editor h2 {
    color: #000;
    text-align: left;
    margin: 20px 0;
}

.criar-post {
    padding: 100px 0 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-criar-post {
    width: 70%;
}

.form-criar-post {
    display: flex;
    flex-direction: column;
}

.group-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cancel {
    background-color: transparent;
    border: 1px solid #666666;
    color: #666666;
    font-weight: 600;
}

.cancel:hover {
    background-color: transparent;
    border: 1px solid #333333;
    color: #333333;
    font-weight: 600;
}

.save {
    background-color: #666666;
    border: 1px solid #666666;
    color: #fff;
    font-weight: 600;
}

.save:hover {
    background-color: #cdcdcb;
    border: 1px solid #cdcdcb;
    color: #666666;
    font-weight: 600;
}

.botao-orcamento:disabled {
    background-color: #cdcdcb;
    border: 1px solid #cdcdcb;
    color: #666666;
    font-weight: 600;
    cursor: not-allowed;
}

.botao-orcamento.save[disabled]:hover::before {
    content: "Preencha todos os campos!";
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    color: red;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 1000;
}

.add-image-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px dashed rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    color: rgb(99, 99, 99);
    margin-bottom: 20px;
    width: 100%;
}

.add-image-post img {
    height: 300px;
    object-fit: cover;
    padding-bottom: 20px;
}


.form-criar-post input {
    border: 0;
    outline: none;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;

}



.upload-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
}

#imagem-criar-post {
    display: none;
}

.icon-upload {
    margin-right: 10px;
}


.container-criar-post .botao-orcamento {
    padding: 10px 0;
    width: 20%;
    font-weight: 500;
    font-size: 14px;
    border-radius: 6px;
    margin: 20px 0;
}

.intro-criar-post {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 40px;
    color: var(--cor-principal);
}

.intro-criar-post h2 {
    font-size: 26px;
}

.icon-add-post {
    font-size: 24px;
    margin-right: 10px;
}

.container-criar-post select {
    padding: 10px;
    width: 100%;
    border-radius: 6px;
    margin-top: 10px;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid rgba(128, 128, 128, 0.5);
}
.select-post {
    width: 50%;
}

.data-post {
    margin-left: 20px;
    width: 50%;
}


.data-post input {
    padding: 8px;
    width: 100%;
    margin-top: 10px;
}

.container-criar-post label {
    margin: 20px 0 10px 0;
    font-size: 16px;
    font-weight: 500;
}

.container-criar-post input::placeholder {
    font-size: 16px;
}


#imagem-criar-post {
    border: 0;
    margin: 0;
}

.group-infos {
    display: flex;
}

.form-criar-post textarea {
    margin-top: 20px;
    border: 0;
    outline: none;
    padding: 10px 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    font-size: 18px;
    font-weight: 400;
    height: 200px;
    resize: none;
    font-family: 'Open Sans';
}

.conteudo-criar-post {
    display: flex;
    flex-direction: column;
}
.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

@media (max-width: 767px) {
    .container-criar-post select option {
        font-size: 12px;
    }

    .criar-post {
        padding: 120px 0 40px 0;
    }

    .container-criar-post {
        width: 90%;
    }

    .container-criar-post .botao-orcamento {
        width: 40%;
    }

    .intro-criar-post h2 {
        font-size: 20px;
    }


    .form-criar-post input {
        font-size: 16px;
    }

    .add-image-post img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        padding: 0px 20px 20px 20px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .criar-post {
        padding: 120px 0 40px 0;
    }

    .container-criar-post {
        width: 80%;
    }

    .container-criar-post .botao-orcamento {
        width: 30%;
    }

    .container-criar-post select option {
        font-size: 12px;
    }

}

@media (min-width: 1025px) and (max-width: 1367px) {}

.botao-orcamento.save.loading::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: transparent;
    animation: spin 0.8s linear infinite;
    margin-left: 8px;
    vertical-align: middle;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }