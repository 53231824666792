.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999;
    /* Garante que o modal esteja acima de todos os outros elementos */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fundo escurecido */
    z-index: 99999999999999999999;
    /* Coloque o overlay um nível abaixo do modal */
}


.modal-main {
    border-radius: 6px;
    background-color: #fff;
    width: 40%;
    text-align: center;
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 20px;
    margin-top: 40px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal-buttons {
    margin-top: 10px;
}


.btn-excluir-modal {
    background-color: transparent;
    border: 1px solid #ff0000;
    background-color: #ff0000;
    padding: 8px 0;
    font-size: 14px;
    margin-left: 20px;
}

.btn-cancel-modal {
    background-color: transparent;
    color: #333333;
    border: 1px solid #333333;
    padding: 8px 0;
    font-size: 14px;
}


.btn-cancel-modal:hover {
    background-color: transparent;

}

.btn-excluir-modal:hover {
    background-color: transparent;
    border: 1px solid #ff0000;
    background-color: #ff0000;
    padding: 8px 0;
    font-size: 14px;
}

@media (max-width: 767px) {

    .modal-main {
        border-radius: 6px;
        background-color: #fff;
        width: 90%;
        text-align: center;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 20px;
    }

    .modal-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    .modal-main h2 {
        width: 80%;
        text-align: center;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .modal-main {
        border-radius: 6px;
        background-color: #fff;
        width: 90%;
        text-align: center;
        padding: 40px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 20px;
    }

    .modal-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    .modal-main h2 {
        width: 80%;
        text-align: center;
    }
}