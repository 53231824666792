.container-panel {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;

}

.content-area-login {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    background-color: var(--cor-principal);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.247) 0px 3px 8px;
}

.header-login {
    margin-bottom: 0px;
}

.header-login .logo {
    width: 120px;
    margin-bottom: 10px;
}


.header-login h1 {
    font-size: 20px;
}

.form-login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.form-login .form-group {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.form-group label {
    font-weight: 500;
   }

.form-login .submit-button {
    background-color: #224579;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    float: left;
    width: 100%;
    margin-top: 10px;
    transition: background-color 0.3s, transform 0.3s;
}

.form-group input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f9f9f9;
    font-family: 'Open Sans', sans-serif;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus {
    border-color: var(--cor-principal);
    box-shadow: 0 0 5px rgba(110, 142, 251, 0.5);
    outline: none;
}

.container-panel a{
    color: #fff;
    text-decoration: none;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .container-panel {
        width: 100%;
        justify-content: start;
    }

    .content-area-login {
        padding: 50px 30px;
        margin-top: 100px;
        width: 80%;
        height: 450px;
    }

    .header-login .logo {
        width: 150px;
        margin-bottom: 20px;
    }

    .header-login h1 {
        font-size: 20px;
    }

    .form-group label {
        font-size: 18px;
    }

    .form-group input {
        font-size: 16px;
        padding: 10px;
    }

    .btn-login {
        width: 80%;
        margin: 10px auto;
        padding: 8px 0;
        border-radius: .3rem;
        border: 0;
        color: #fff;
        font-size: 16px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

}

.access-registration {
    display: none;
}