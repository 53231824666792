.banner {
    position: relative;
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    overflow: hidden;
}

.banner::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #ff6600cb;
    z-index: 1;
}

.banner-content {
    z-index: 2;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    /* Ajuste o padding conforme necessário */
    text-align: center;
}

.banner h1 {
    margin: 0;
    font-size: 42px;
}

@media (max-width: 767px) {
    .banner {
        position: relative;
        width: 100%;
        height: 25vh;
        padding: 0 20px;
        margin-top: 150px;
    }

    .banner-content {
        padding: 10px;
    }

    .banner::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #ff6600cb;
        z-index: 1;
    }

    .banner h1 {
        font-size: 26px;
    }

    .banner .banner-button {
        width: 100%;
    }

}