.textos-info-servicos {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.content-textos {
    padding-top: 40px;
    width: 70%;
}

.textos-info-servicos h3 {
    font-size: 26px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin: 40px 0;
}

.textos-info-servicos .textos-ao-lado {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-top: 20px;
    margin-bottom: 80px;
}

.textos-info-servicos .textos-ao-lado p,
.textos-info-servicos p {
    font-size: 18px;
}

@media (max-width: 766px) {
    .textos-info-servicos {
        justify-content: center;
    }

    .content-textos {
        width: 90%;
    }

    .textos-info-servicos h3 {
        font-size: 18px;
        margin-bottom: 0px;
        color: #333333;

    }

    .textos-info-servicos .textos-ao-lado {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .textos-info-servicos .textos-ao-lado p {
        font-size: 16px;
        color: #333333;
    }

}

@media (min-width: 767px) and (max-width: 1024px) {

    .textos-info-servicos h1 {
        padding: 0 80px;
        text-align: center;
    }

    .content-textos {
        width: 80%;
    }

    .textos-info-servicos h3 {
        font-size: 20px;
        margin-bottom: 0px;
        text-align: left;
        color: #333333;
        padding-bottom: 20px;
    }

    .textos-info-servicos .textos-ao-lado {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }

    .textos-info-servicos .textos-ao-lado p {
        font-size: 16px;
    }
}

@media (min-width: 1025px) and (max-width: 1367px) {

    .content-textos {
        width: 80%;
    }

    .textos-info-servicos h2 {
        font-size: 28px;
    }

    .textos-info-servicos h3 {
        font-size: 20px;
        padding: 0 80px;
    }

    .textos-info-servicos .textos-ao-lado {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .textos-info-servicos .textos-ao-lado p {
        font-size: 16px;
    }
}