.banner-home {
    position: relative;
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
}

.banner-home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0a0d17b0;
    /* cor #979797 com 70% de opacidade */
    z-index: 1;
}

.banner-content-home{
    position: relative;
    z-index: 2;
    width: 100%;
}

.banner-home h1 {
    margin: 0;
    font-size: 52px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .banner-home {
        position: relative;
        width: 100%;
        height: 40vh;
        padding: 0 20px;
        margin-top: 150px;
    }

    .banner-content-home {
        position: relative;
        z-index: 2;
    }

    .banner-home h1 {
        font-size: 28px;
    }

    .banner-home .banner-button {
        width: 100%;
    }

}