

.cardsInfo .box-cardsInfo-descricao {
    padding: 20px;
    border: 1px solid var(--cor-principal);
    height: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.cardsInfo {
    height: 100%;
    padding: 20px 0px 40px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--cor-secundaria);
}

.group-cardsInfo{
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.cardsInfo-descricao img {
    width: 100%;
    height: 100%;
}

.icone-cards-info{
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-cardsInfo-descricao h3 {
    padding: 10px 0;
    font-size: 22px;
    font-weight: bold;
}

.box-cardsInfo-descricao p {
    font-weight: 500;
    width: 100%;
    line-height: 1.2em;
    color: var(--cor-secundaria);
}


@media (max-width: 766px) {

    .cardsInfo {
        padding: 20px 0px 40px 0px;
    }

    .group-cardsInfo{
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    
    
    .cardsInfo .box-cardsInfo-descricao {
        padding: 20px;
        width: 100%;
    }
    
    .cardsInfo h5 {
        display: none;
    }

    .cardsInfo h2 {
        font-size: 28px;
    }
    
    .card-descricao-cardsInfo h3 {
        margin-bottom: 10px;
        font-size: 26px;
        font-weight: bold;
    }
    
    .card-descricao-cardsInfo p {
        font-weight: 500;
        width: 80%;
        line-height: 1em;
    }
    
}

@media (min-width: 767px) and (max-width: 1024px) {
    .group-cardsInfo{
        width: 80%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
    
    
    .cardsInfo .box-cardsInfo-descricao {
        width: 100%;
        padding: 20px 10px;
    }
    
    .cardsInfo h5 {
        display: none;
    }
    
    .box-cardsInfo-descricao h3 {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.2em;
    }
    
    .box-cardsInfo-descricao p {
        font-weight: 500;
        width: 80%;
        font-size: 14px;
        line-height: 1em;

    }

}

@media (min-width: 1025px) and (max-width: 1367px) {
    .group-cardsInfo{
        width: 80%;
    }
    
    .cardsInfo .box-cardsInfo-descricao {
        width: 100%;
    }
    
    .cardsInfo h5 {
        display: none;
    }

    .cardsInfo h2 {
        font-size: 28px;
    }
    
    .card-descricao-cardsInfo h3 {
        margin-bottom: 10px;
        font-size: 26px;
        font-weight: bold;
    }
    
    .card-descricao-cardsInfo p {
        font-weight: 500;
        width: 80%;
        line-height: 1em;
    }
}